import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const NotFound = ({ setLanguage, idioma }) => {
    const [texto, setTexto] = useState({});

    useEffect(() => {
        if (idioma) {
          setTexto({
            title: "Page not found!",
            contenido: "Oops! You seem to be lost. Here are some helpful links.",
            lan: "en",
          });
        } else {
          setTexto({
            title: "Página no disponible!",
            contenido: "Oops! Creo que estas un poco perdido. Aquí tienes algunos links que te ayudarán.",
            lan: "es",
          });
        }
      }, [idioma]);

    return (
        <div class="container m-auto">
            <div class="row">
                <div class="col-md-12 text-center intro-single">
                    <h1>404</h1>
                    <h3>{texto.title}</h3>
                    <p>{texto.contenido}</p>
                </div>
            </div>
        </div>
    )
}

export default NotFound