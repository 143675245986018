import React, { useState, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Research from "../componentes/subsecciones/Research";

import background1 from "../img/carrusel/foto_grupo_1.jpg";
import background2 from "../img/carrusel/foto_grupo_2.jpg";
// style={{ backgroundImage: `url(${background3})` }}
import "../css/carrusel.css";

const Carrusel = ({ idioma }) => {
  const [texto, setTexto] = useState({});

  useEffect(() => {
    if (idioma) {
      setTexto({
        title: "Research Group",
      });
    } else {
      setTexto({
        title: "Grupo de Investigación",
      });
    }
  }, [idioma]);

  return (
    <div>
      <Carousel>
        <Carousel.Item interval={2000}>
          <img
            className="img-carrusel"
            src={background2}
            alt="Primera imagen grupo de investigación"
          />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            className="img-carrusel"
            src={background1}
            alt="Segunda imagen grupo de investigación"
          />
        </Carousel.Item>
      </Carousel>
      <Research idioma={idioma}/>
    </div>
  );
};

export default Carrusel;
